import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MainComponent} from './main/main.component';
import {AuthGaurd} from '../../../../common/_guards/Auth.guard';

const routes: Routes = [
    {
        path: '', component: MainComponent, canActivate: [AuthGaurd],
        children: [
            {path: '', redirectTo: 'analytics', pathMatch: 'full'},
            {
                path: 'analytics',
                loadChildren: () => import('./analytics-section/analytics-section.module').then(m => m.AnalyticsSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'assessments',
                loadChildren: () => import('./exam-section/exam-section.module').then(m => m.ExamSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings-section/settings-section.module').then(m => m.SettingsSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'student',
                loadChildren: () => import('./student-section/student-section.module').then(m => m.StudentSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'marketing',
                loadChildren: () => import('./marketing-section/marketing-section.module').then(m => m.MarketingSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'statistics',
                loadChildren: () => import('./statistics-section/statistics-section.module').then(m => m.StatisticsSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'video',
                loadChildren: () => import('./video-section/video-section.module').then(m => m.VideoSectionModule),
                canActivate: [AuthGaurd]
            },
            {
                path: 'workflow',
                loadChildren: () => import('./workflow-section/workflow-section.module').then(m => m.WorkflowSectionModule),
                canActivate: [AuthGaurd]
            }
        ]
    },
    {
        path: 'login',
        loadChildren: () => import('../../../../common/login/azure-login/azure-login.module')
            .then(m => m.AzureLoginModule)
    },
    {
        path: 'logoff',
        loadChildren: () => import('./management-portal-logoff.component/management-portal-logoff.component.module')
            .then(m => m.ManagementPortalLogoffComponentModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
